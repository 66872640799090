import React from "react";
import Layout from "../components/layout";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../components/seo";

const Kolacici = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "kolacici" })} />
      <div className="section blue-background pravila">
        <div className="content">
          <div className="card">
            <h1>{intl.formatMessage({ id: "kolacici" })}</h1>
            <p>
              {intl.formatMessage(
                { id: "kolacici.tekst" },
                {
                  br: "\n",
                  p: (str) => <p>{str}</p>,
                  h2: (str) => <h2>{str}</h2>,
                }
              )}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Kolacici;
